<template>
  <!-- <v-layout max-width class="flex-column white"> -->
  <v-layout class="flex-column white pb-6">
    <div class="">
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="search.pageSize"
        class="pb-5 max-width pa-4 header-grey"
        hide-default-footer
        :loading="listLoading"
        item-key="id"
        no-data-text="暂无查询数据"
        disable-sort
      >
        <template v-slot:item.rowIndex="{index}">
          {{ (index+1)+(search.pageSize*(search.pageNo-1)) }}
        </template>
        <template v-slot:item.jobClassName="{item}">
          <div class=" font-overflow" style="max-width:280px;" :title="item.jobClassName">{{ item.jobClassName }}</div>
        </template>
        <template v-slot:item.cronExpression="{item}">
          <div class=" font-overflow" style="max-width:280px;" :title="item.cronExpression">{{ item.cronExpression }}</div>
        </template>
        <template v-slot:item.parameter="{item}">
          <div class=" font-overflow" style="max-width:280px;" :title="item.parameter">{{ item.parameter }}</div>
        </template>
        <template v-slot:item.description="{item}">
          <div class=" font-overflow" style="max-width:250px;" :title="item.description">{{ item.description }}</div>
        </template>
        <template v-slot:item.status_dictText="{item}">
          <span :class="item.status===-1?'error--text':''">{{ item.status_dictText }}</span>
        </template>
        <template v-slot:item.active="{item}">
          <div class=" flex-y-center">
            <!-- 启动、停止、立即执行 -->
            <!-- <a :disabled="item.status===-1" @click="activeList($api.sysQuartzJobPause,'get','停止',{id:item.id})">停止</a>
            <a :disabled="item.status===0" class=" px-2" @click="activeList($api.sysQuartzJobResume,'get','恢复',{id:item.id})">恢复</a>
            <a :disabled="item.status!==0" @click="activeList($api.sysQuartzJobExecute,'get','执行',{id:item.id})">执行</a> -->
            <v-icon :disabled="item.status===-1" color="error" size="20" title="停止" class=" mr-1" @click="activeList($api.sysQuartzJobPause,'get','停止',{id:item.id})">mdi-pause-circle-outline</v-icon>
            <v-icon :disabled="item.status===0" color="primary" size="20" class=" mr-1" title="恢复" @click="activeList($api.sysQuartzJobResume,'get','恢复',{id:item.id})">mdi-replay</v-icon>
            <v-icon :disabled="item.status!==0" color="success" size="20" title="执行" class=" mr-1" @click="activeList($api.sysQuartzJobExecute,'get','执行',{id:item.id})">mdi-play-circle-outline</v-icon>
          </div>
        </template>
      </v-data-table>
      <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end">
        <pagination v-model="search.pageNo" :size="search.pageSize" :total="total" @input="getList" @changePageSize="changePageSize" />
      </div>
    </div>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0,
      listLoading: false,
      btnLoading: false,
      headers: [
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '任务类名', value: 'jobClassName' },
        { text: 'Cron表达式', value: 'cronExpression' },
        { text: '参数', value: 'parameter' },
        { text: '描述', value: 'description' },
        { text: '状态', value: 'status_dictText' },
        { text: '操作', value: 'active', sortable: false }
      ],
      list: []
    }
  },
  watch: {
  },
  mounted() {
    this.initList()
  },
  methods: {
    initList() {
      this.search.pageNo = 1
      this.getList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.getList()
    },
    //  获取任务定时列表
    getList() {
      this.total = 0
      this.listLoading = true
      const search = { ...this.search.data }
      search.pageNo = this.search.pageNo
      search.pageSize = this.search.pageSize
      this.$http.get(this.$api.sysQuartzJobList, { data: { ...search }}).then(res => {
        this.listLoading = false
        this.list = res.result && res.result.records || []
        this.total = res.result && res.result.total || 0
      }).catch(() => {
        this.listLoading = false
      })
    },
    // 操作其他按钮 确认禁用、恢复、删除
    activeList(api, method, title, par) {
      this.$confirm({ text: '是否确认' + title + '这条记录？' }).then((vm) => {
        const param = new URLSearchParams()
        for (const i in par) {
          param.append(i, par[i] || '')
        }
        vm.loading = true
        this.$http[method](api, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          if (method === 'delete') {
            this.initList()
          } else {
            this.getList()
          }
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title{
    width:160px;
    color: #777;
}
// ::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
//     border-bottom: thin solid rgb(156, 156, 156, .12) !important;
// }

</style>
